<template>
  <div class="animated fadeIn">

    <b-row>

      <b-col sm="12">
        <b-card>

          <form @submit.stop.prevent="onSubmit">

            <b-row>
              <b-col sm="6">
                <b-form-group>
                  <label for="name">Name</label>
                  <b-form-input type="text" id="name" v-model="name" placeholder="Vet name" name="name"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group>
                  <label for="postcode">Postcode</label>
                  <b-form-input type="text" id="postcode" v-model="postcode" placeholder="Vet postcode" name="postcode"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <div slot="footer">
              <b-button :to="{ name: 'Search' }" size="md" variant="outline-secondary" class="pull-left">
                Back
              </b-button>
              <b-button type="submit" size="md" variant="primary" class="pull-right">
                Submit
              </b-button>
            </div>

          </form>

        </b-card>

      </b-col>

    </b-row>

    <v-server-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable" class="table" ref="table" v-show="show">

      <template slot="actions" slot-scope="props">
        <b-button variant="secondary" :to="{ name: 'Vet', params: { id: props.row.id } }">View</b-button>
      </template>

    </v-server-table>

  </div>
</template>

<script>
import Vue from 'vue'
import {ServerTable} from 'vue-tables-2'
import axios from '../../../shared/axios/auth'
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

Vue.use(ServerTable)

export default {
  components: {
    ServerTable
  },
  mixins: [validationMixin],
  data () {
    return {
      show: false,
      search: this.$route.params.search,
      searchselected: null,
      name: null,
      postcode: null,
      // Data Table
      columns: [
          'name', 'email', 'telephone', 'actions'
      ],
      data: [],
      fields: [
      ],
      options: {
        requestFunction: (data) => {
          let new_params  = {};
          let n_params = {
            product: localStorage.getItem('selected_product'),
            filter: this.searchselected

          };
          if (this.search) {
            let params_ = {
              search: this.search
            }
            new_params = Object.assign({}, params_, data, n_params)
          } else {
            new_params = Object.assign({}, data, n_params);
          }

          //let url = '/mta/?product=' + localStorage.getItem('selected_product');

          return axios.get('/users/vet/search/', {
              params: new_params
          }).catch(error => {
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });
        },
        responseAdapter: function(resp) {
          console.log('adapter');
          var data = this.getResponseData(resp);
          this.show = 'true'
          return { data: data.data.data, count: data.data.pagination.total }
        },
        headings: {},
        sortable: [],
        sortIcon: { base:'fa', up:'fa-sort-asc', down:'fa-sort-desc', is:'fa-sort' },
        filterable: false
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default'
    }
  },
  validations: {
  },
  methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v[name];
        return $dirty ? !$error : null;
      },
    onSubmit () {
        //this.showAPIError = false;
        this.$v.$touch();
        if (this.$v.$anyError) {
          return;
        }

        let search_params = '';
        if (this.name) {
          search_params += 'name=' + this.name
        }
        if (this.postcode) {
            if (search_params) {
              search_params += '&postcode=' + this.postcode
            } else {
              search_params += 'postcode=' + this.postcode
            }
        }

        axios.get('/users/vet/search/?' + search_params).then(res => {
          this.data = res
          this.$refs.table.refresh();
          this.show = 'true'

        }).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
              console.log('post error');
              console.log(error);
          })
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
